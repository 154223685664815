
@import "./assets/theme/scss/variables.scss";
@import '~bootstrap/scss/bootstrap';

@import "./assets/theme/css/style.css";
@import "./assets/theme/vendor/remixicon/remixicon.css";
@import "./assets/theme/vendor/boxicons/css/boxicons.min.css";


body {
	font-family: "Poppins", 'sans-serif';
}

a {
	text-decoration: none;
}

h1{
	font-size: 2rem;
	font-weight: 900;
}

h2 {
	font-size: 1.75rem;
	font-weight: 700;
}

h3 {
	font-size: 1.5rem;
	font-weight: 600;
}

h4 {
	font-size: 1.25rem;
	font-weight: 500;
}

h5 {
	font-size: 1rem;
	font-weight: 500;
}

h6 {
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control:focus {
	border-color: $primary;
	box-shadow: none;
}

.pointer {
	cursor: pointer;
}

// overload tel input

.iti {
	display: block!important;
}

.iti__selected-flag.dropdown-toggle {
	background-color: #F5F5F5;
}